import axios from 'axios'

const apiClient = axios.create({
  baseURL: '',
  withCredentails: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application.json'
  }
})

export default {
  getNewsList() {
    return apiClient.get('/data/news.json')
  },
  getNewsData() {
    return apiClient.get('/data/news.json')
  }
}