<template src="./resume_modal.html"></template>
<script>
const RESUME_ID = process.env.VUE_APP_RESUME_ID || 206890
export default {
    data() {
        return {
          show: true,
          resumeId: RESUME_ID
        }
    },
    computed: {
      lang() {
        return this.$store.state.lang
      },
    },
    methods: {
      isShow() {
        return this.$route.name === 'Home' && this.show
      },
      isHide() {
        return this.$route.name === 'Home' && !this.show
      }
    },
}
</script>
<style src="./resume_modal.scss" scoped lang="scss"></style>