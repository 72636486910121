<template src="./werlotus.html"></template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap/dist/js/bootstrap.bundle'


import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import searchBG from '@/components/searchBG.vue'; // @ is an alias to /src

// @Component({
//   components: {
//     searchBG,
//   },
// })
export default {
    components: {
        searchBG,
    },
    data() {
        return {
            page : 1,
        }
    },
    computed: {
    source() {
      return this.$store.getters.getSource['aboutUs']
    }
  },
}
</script>
<style src="./werlotus.scss" scoped lang="scss"></style>
