<template src="./sp_detail.html"></template>

<script scoped>
import { Component, Prop, Vue } from "vue-property-decorator";
import $ from "jquery";

window.$ = $;
import "bootstrap/dist/js/bootstrap.bundle";
window.jQuery = $;
import "../../assets/styles/dropdown.scss";

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import searchBG from "@/components/searchBG.vue"; // @ is an alias to /src
import EventService from "@/services/EventService.js";
export default {
  components: {
    searchBG
  },
  data() {
    return {
      mainImg: require(`@/assets/images/icon/Job_banner.png`),
      filter: 0,
      dfilter: 0,
      success: false,
      selected: 1,
      job: {},
      isLoading: true,
      fullPage: true,
      listAction: [
        { name: "Clear All", code: 1 },
        { name: "Apply", code: 2 }
      ],
      loader: null,
      url: window.location.href
    };
  },
  computed: {
    salary_display() {
      let text = "";
      if (this.job.salary_min && this.job.salary_max) {
        text = `${this.job.salary_min} - ${this.job.salary_max}`;
      } else if (this.job.salary_min) {
        text = this.job.salary_min;
      } else if (this.job.salary_max) {
        text = this.job.salary_max;
      }

      return text;
    },
    source() {
      return this.$store.getters.getSource["job-detail"];
    },
    source2() {
      return this.$store.getters.getSource["sp"];
    },
    sourceLink() {
      return this.$store.getters.getSource["link"];
    }
  },
  methods: {
    displayJobName(job) {
      if (!job || !job.position_name) return;
      if (this.$store.state.lang === "TH") {
        return job.custom_fields?.positionnameth
          ? job.custom_fields.positionnameth
          : job.position_name;
      }
      return job.position_name;
    },
    displayDescription(job) {
      if (!job || !job.description) return;
      return job.description;
    },
    setDetail(val) {
      if (!val) return (this.dfilter = 0);
      if (window.innerWidth > 994) {
        // setTimeout(() => {
        this.dfilter = this.filter;
        // }, 1000);
      } else {
        this.dfilter = this.filter;
      }
    },
    clickMore() {
      $(".list-BG").addClass("h-auto");
      $(".more-BG").remove();
    }
  },
  created() {
    const loader = this.$loading.show();
    EventService.getJobById(this.$route.params.id)
      .then(response => {
        this.job = response.data;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        loader.hide();
      });
  },
  mounted() {}
};
</script>

<style src="./sp_detail.scss" scoped lang="scss"></style>
