<template src="./news.html"></template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import $ from "jquery";
window.$ = $;
window.jQuery = $;
import "bootstrap/dist/js/bootstrap.bundle";

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import searchBG from "@/components/searchBG.vue"; // @ is an alias to /src
import LocalEventService from "@/services/LocalEventService.js";
import EventService from "@/services/EventService.js"

export default {
  components: {
    searchBG
  },
  data() {
    return {
      lang: this.$store.state.lang,
      newsList: [],
      categories: [],
      page: 1,
      totalPage: 1,
      menuTd: 0,
      newsImg: [
        require("@/assets/images/icon/news_1.png"),
        require("@/assets/images/icon/news_2.png"),
        require("@/assets/images/icon/news_1.png"),
        require("@/assets/images/icon/news_3.png"),
        require("@/assets/images/icon/news_4.png")
      ]
    };
  },
  watch: {
    menuTd: function(newMenu, oldMenu) {
      this.page = 1
    }
  },
  computed: {
    newsListByCategory: function() {
      const list = this.newsList.filter(n => n.category === this.categories[this.menuTd])
      this.totalPage = 1 // news per page?
      return list
    },
    newsDataByCategory: function() {
      const newsListByCategory = this.newsList.filter(n => n.category === this.categories[this.menuTd])
      this.totalPage = 1 // news per page ?
      return {
        top: newsListByCategory.slice(0, 3),
        bottom: newsListByCategory.slice(3)
      }
    },
    source() {
      return this.$store.getters.getSource['news']
    }
  },
  methods: {
    getNewsCls(index) {
      switch(index) {
        case 0:
        case 3:
          return 'col-md-8 web'
        default:
          return 'col-md-4 web'
      }
    },
    getNewsImage(news, index) {
      switch(index) {
        case 0:
        case 3:
          return news.thumbnail_lg
        default:
          return news.thumbnail
      }
    },
    fetchNews() {
      const newsList = EventService.getNews();
      this.newsList = newsList.sort((a,b) => {
            return b.id - a.id
      })
      const category_set = new Set(newsList.map(n => n.category));
      this.categories = [...category_set];
      // old code
      // EventService.getNews()
      //   .then(response => {
      //     const newsList = response.data;
      //     this.newsList =   newsList.sort((a,b) => {
      //       return b.id - a.id
      //     });
      //     const category_set = new Set(newsList.map(n => n.category));
      //     this.categories = [...category_set];
      //   })
      //   .catch (error => {
      //     console.log(error)
      //   })
    },
  },
  created() {
    this.fetchNews()
    // LocalEventService.getNewsData()
    //   .then(response => {
    //     const newsList = response.data;
    //     this.newsList = newsList;
    //     const category_set = new Set(newsList.map(n => n.category));
    //     this.categories = [...category_set];
    //     this.fetchNews()
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }
};
</script>
<style src="./news.scss" scoped lang="scss"></style>
