<template src="./job_not_found.html"></template>

<script scoped>
import { Component, Prop, Vue } from 'vue-property-decorator';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap/dist/js/bootstrap.bundle'
// import '@/assets/styles/theme.scss'
import '../../assets/styles/dropdown.scss'


import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import searchBG from '@/components/searchBG.vue'; // @ is an alias to /src
import EventService from '@/services/EventService.js'
export default {
    components: {
        searchBG,
    },
    data() {
        return {
            mainImg : require(`@/assets/images/icon/Job_banner.png`),
            filter : 0,
            dfilter : 0,
            success : false,
            selected: 1,
            listAction: [
                {name: 'Clear All', code: 1},
                {name: 'Apply', code: 2},
		    ],
            isLoading: true,
            job: {},
        }
    },
    computed: {
        source() {
        return this.$store.getters.getSource['job-not-found']
        }
    },
    methods: {
        setDetail(val){
            if(!val) return this.dfilter = 0
            if(window.innerWidth>994){
                // setTimeout(() => {
                    this.dfilter = this.filter 
                // }, 1000);
            }else{
                this.dfilter = this.filter 
            }
        },
        clickMore(){
            $('.list-BG').addClass('h-auto')
            $('.more-BG').remove()
        }
    },
    created() {
    },
    mounted () { 
        // if(window.innerWidth>994){
            setTimeout(() => {
                this.success = true
            }, 1100);
        // }else{
        //     this.success = true
        // }
        // this.$nextTick(function () {
        // })
    },
    
}
</script>
<style src="./job_not_found.scss" scoped lang="scss"></style>
