<template src="./job_list.html"></template>

<script scoped>
import { Component, Prop, Vue } from "vue-property-decorator";
import $ from "jquery";
window.$ = $;
window.jQuery = $;
import "bootstrap/dist/js/bootstrap.bundle";
// import '@/assets/styles/theme.scss'
import "../../assets/styles/dropdown.scss";

import VueCarousel from "vue-carousel";
import EventService from "@/services/EventService.js";
Vue.use(VueCarousel);

import searchBG from "@/components/searchBG.vue"; // @ is an alias to /src

const jobBanners = {
  "head-office": "/static/assets/jobs/ho_small.jpg",
  dc: "/static/assets/jobs/dc_small.jpg",
  store: "/static/assets/jobs/store_small.jpg",
};

const headOfices = {
  CEO: {
    id: "CEO",
    name: "CEO Office",
    area: "Head Office",
  },
  "Commercial Food": {
    id: "Commercial Food",
    name: "Product Food",
    area: "Head Office",
  },
  "Commercial Non Food": {
    id: "Commercial Non Food",
    name: "Product Non Food",
    area: "Head Office",
  },
  COO: {
    id: "COO",
    name: "Operations and Supply Chain",
    area: "Head Office",
  },
  CPSO: {
    id: "CPSO",
    name: "HR, Corporate Affairs and Legal",
    area: "Head Office",
  },
  Customer: {
    id: "Customer",
    name: "Marketing",
    area: "Head Office",
  },
  "Finance & Accounting": {
    id: "Finance & Accounting",
    name: "Finance & Accounting",
    area: "Head Office",
  },
  "Fresh Foods & B2B": {
    id: "Fresh Foods & B2B",
    name: "B2B Fresh Food",
    area: "Head Office",
  },
  "Group e-Commerce": {
    id: "Group e-Commerce",
    name: "O2O Business",
    area: "Head Office",
  },
  Malls: {
    id: "Malls",
    name: "Malls",
    area: "Head Office",
  },
  "Non Head Office": {
    id: "Non Head Office",
    name: "Operation Audit",
    area: "Head Office",
  },
  Property: {
    id: "Property",
    name: "Property",
    area: "Head Office",
  },
  Technology: {
    id: "Technology",
    name: "Technology/IT",
    area: "Head Office",
  },
};

const dcs = {
  "DC Bangbuathong": {
    id: "DC Bangbuathong",
    name: "DC Bangbuathong",
    area: "DC",
  },
  "DC Wangnoi": {
    id: "DC Wangnoi",
    name: "DC Wangnoi",
    area: "DC",
  },
  "DC Lumlukka": {
    id: "DC Lumlukka",
    name: "DC Lumlukka",
    area: "DC",
  },
  "DC Khon Kaen": {
    id: "DC Khon Kaen",
    name: "DC Khon Kaen",
    area: "DC",
  },
  "DC Sam Khok": {
    id: "DC Sam Khok",
    name: "DC Sam Khok",
    area: "DC",
  },
};

const stores = {
  "Big Format": {
    id: "Big Format",
    name: "Big Format",
    area: "Store",
  },
  "Talad Format": {
    id: "Talad Format",
    name: "Talad Format",
    area: "Store",
  },
  "Go Fresh": {
    id: "Go Fresh",
    name: "Go Fresh",
    area: "Store",
  },
};

const areaOfWorks = ["Head Office", "Store", "DC"];

export default {
  components: {
    searchBG,
  },
  data() {
    return {
      page: 1,
      totalPage: 1,
      jobs: [],
      filterJobs: [],
      mainImg: require(`@/assets/images/icon/Job_banner.png`),
      filter: 0,
      filterBar: {
        area: false,
        department: false,
        location: false,
        contract: false,
      },
      dfilter: 0,
      success: false,
      selected: 1,
      listAction: [
        { name: "Clear All", code: 1 },
        { name: "Apply", code: 2 },
      ],
      departments: [],
      joblocates: [],
      organizations: [],
      o_filters: [],
      o_map: {},
      d_map: {},
      s_map: {},
      checkedLocates: [],
      d_filters: [],
      s_filters: [],
      employeeTypes: [],
      url: window.location.href,
      areaOfWorks,
      isLoading: true,
      departmentData: {
        "Head Office": [],
        Store: [],
        DC: [],
      },
      allDepartments: [],
    };
  },
  watch: {
    checkedLocates: function (val) {
      this.page = 1;
      this.makeFilterJobs();
    },
    employeeTypes: function (val) {
      this.page = 1;
      this.makeFilterJobs();
    },
    $route: function () {
      this.initialJobs();
    },
  },
  computed: {
    getBanner() {
      const banner = jobBanners[this.$route.params.slug];
      if (banner) return banner;
      return "/static/assets/jobs/default_small.jpg";
    },
    lang() {
      return this.$store.state.lang;
    },
    hasSlug: function () {
      if (
        this.$route.params &&
        this.$route.params.slug &&
        this.$route.params.slug !== "all"
      )
        return true;
      return false;
    },
    getDepartment: function () {
      switch (this.$route.params.slug) {
        case "head-office":
          return "Head Office";
        case "store":
          return "Store";
        case "dc":
          return "DC";
        case "special-program":
          return "Special Program";
        case "o2o":
          return "O2O";
        default:
          return "all";
      }
    },
    listCls: function () {
      if (this.filter) {
        return `z-${this.filter}`;
      }

      return "z-0";
    },
    source() {
      return this.$store.getters.getSource["job-list"];
    },
    sourceLink() {
      return this.$store.getters.getSource["link"];
    },
    sourceDetail() {
      return this.$store.getters.getSource["job-detail"];
    },
    getShowPages() {
      if (this.totalPage <= 10) return this.totalPage;

      const list = Array.from({ length: this.totalPage }, (_, i) => i + 1);
      if (this.page - 5 <= 0) {
        return list.slice(0, 10);
      }

      if (this.page + 5 >= this.totalPage) {
        return list.slice(this.totalPage - 10, this.totalPage);
      }

      return list.filter((n) => n > this.page - 5 && n <= this.page + 5);
    },
  },
  methods: {
    displayJobListName(job) {
      if (this.$store.state.lang === "TH") {
        return job.positionnameth ? job.positionnameth : job.position_name;
      }
      return job.position_name;
    },
    displayOrganizeName(name) {
      switch (name) {
        case "Head Office":
          return this.lang === "EN" ? "Nawamin Office" : "สำนักงานนวมินทร์";
        case "Store":
          return this.lang === "EN" ? name : "สาขา";
        case "DC":
          return this.lang === "EN" ? name : "ศูนย์กระจายสินค้า";
      }

      return "";
    },
    setDetail(val) {
      if (!val) return (this.dfilter = 0);
      if (window.innerWidth > 994) {
        // setTimeout(() => {
        this.dfilter = this.filter;
        // }, 1000);
      } else {
        this.dfilter = this.filter;
      }
    },
    clickMore() {
      $(".list-BG").addClass("h-auto");
      $(".more-BG").remove();
    },
    triggerOrganizeFilter(name) {
      if (this.o_filters.length === 0) {
        this.departments = [];
      }
      if (!this.o_filters.find((o) => o === name)) {
        this.o_filters.push(name);
        this.o_map[name] = true;
        this.departments = [...this.departments, ...this.departmentData[name]];
      } else {
        this.o_filters = this.o_filters.filter((n) => n !== name);
        this.o_map[name] = false;
        this.departments = this.departments.filter((n) => n.area !== name);
      }
      if (this.o_filters.length === 0) {
        this.departments = [...this.allDepartments];
      }
      this.page = 1;
      this.makeFilterJobs();
    },
    triggerDepartmentFilter(name) {
      if (!this.d_filters.find((o) => o.id === name)) {
        const d = this.allDepartments.find((d) => d.id === name);
        this.d_filters.push(d);
        this.d_map[name] = true;
      } else {
        this.d_filters = this.d_filters.filter((n) => n.id !== name);
        this.d_map[name] = false;
      }
      this.page = 1;
      this.makeFilterJobs();
    },
    triggerLocationFilter(name) {
      if (!this.s_filters.find((n) => n === name)) {
        const d = this.joblocates.find((n) => n === name);
        this.s_filters.push(d);
        this.s_map[name] = true;
      } else {
        this.s_filters = this.s_filters.filter((n) => n !== name);
        this.s_map[name] = false;
      }
      this.page = 1;
      this.makeFilterJobs();
    },
    organizationBtnCls(name) {
      if (this.o_map[name]) {
        return "active-btn";
      }
      return "";
    },
    departmentBtnCls(name) {
      if (this.d_map[name]) {
        return "active-btn";
      }
      return "";
    },
    locationBtnCls(name) {
      if (this.s_map[name]) {
        return "active-btn";
      }
      return "";
    },
    makeFilterJobs() {
      let jobs = [...this.jobs];

      if (this.o_filters.length !== 0)
        jobs = jobs.filter((j) => this.o_filters.find((v) => v === j.area));

      if (this.s_filters.length !== 0)
        jobs = jobs.filter((j) =>
          this.s_filters.find((n) => n === j.joblocate)
        );

      if (this.d_filters.length !== 0)
        jobs = jobs.filter((j) =>
          this.d_filters.find((d) => d.id === j.department_name)
        );

      if (this.employeeTypes.length !== 0)
        jobs = jobs.filter((j) =>
          this.employeeTypes.find((e) => e === j.contract_details)
        );

      this.totalPage = Math.ceil(jobs.length / 10);

      let startJob = 0;
      let endJob = 10;

      if (this.page > 1) {
        startJob = (this.page - 1) * 10;
        endJob = this.page * 10;
      }
      this.filterJobs = jobs.slice(startJob, endJob);
    },
    displayJob(job) {
      const list = [];
      if (job.area) list.push(this.displayOrganizeName(job.area));
      if (job.department_name) list.push(job.department_name);
      return list.join(" | ");
    },
    initialJobs() {
      const { q } = this.$route.query;

      this.joblocates = this.$store.state.joblocates;
      this.jobs = this.$store.state.jobs;
      this.departmentData = this.$store.state.departments;
      this.allDepartments = this.$store.state.all_departments;

      if (this.jobs.length === 0) {
        setTimeout(() => {
          this.initialJobs();
        }, 500);
      } else {
        this.isLoading = false;
        if (q) {
          this.filterJobs = this.$store.getters.filterJobs({ query: q });
          this.page = 1;
          this.totalPage = Math.ceil(this.filterJobs.length / 10);
        } else {
          this.filterJobs = this.$store.state.jobs;
          this.page = 1;
          this.totalPage = Math.ceil(this.filterJobs.length / 10);
        }

        if (q && this.filterJobs.length === 0) {
          this.$router.push(`/jobs-not-found`);
        }

        if (
          this.$route.params.slug &&
          this.$route.params.slug !== "all" &&
          this.jobs.length > 0
        ) {
          this.triggerOrganizeFilter(this.getDepartment);
        } else {
          this.departments = [...this.allDepartments];
        }
      }
    },
    resetFilter() {
      this.o_filters = [];
      this.d_filters = [];
      this.s_filters = [];
      this.o_map = {};
      this.d_map = {};
      this.s_map = {};
      this.employeeTypes = [];

      this.page = 1;
      this.makeFilterJobs();
    },
    nextPage() {
      if (this.page < this.totalPage) {
        this.page++;
      }
      this.makeFilterJobs();
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
      }
      this.makeFilterJobs();
    },
    goToPage(page) {
      this.page = page;
      this.makeFilterJobs();
    },
  },

  created() {
    this.initialJobs();
  },
  mounted() {
    // if(window.innerWidth>994){
    setTimeout(() => {
      this.success = true;
    }, 1100);
    // }else{
    //     this.success = true
    // }
    // this.$nextTick(function () {
    // })
  },
};
</script>
<style src="./job_list.scss" scoped lang="scss"></style>
