<template>
  <div id="app">
    <Header/>
    <router-view/>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <!-- <Header/> -->
    <!-- <HelloWorld/> -->
    <Footer/>
    <ResumeModal />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import EventService from '@/services/EventService.js'

// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import Header  from '@/components/header/Header.vue';
import Footer  from '@/components/footer/Footer.vue';
import ResumeModal from '@/components/resume_modal/ResumeModal.vue'
 export default {
     name: 'app',
     components:{
        // HelloWorld,
        Footer,
        Header,
        ResumeModal,
     },
     created() {
      EventService.getAllJobs()
        .then(response => {
          this.$store.commit('setInitialState', response.data)
        })
        .catch(error => {
          console.log(error)
        })
      },
    }
</script>
<style lang="scss">
.pointer {
  cursor: pointer;
}
@media (max-width: 998.89px) { html{overflow-x: hidden;}}
</style>
