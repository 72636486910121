
<template>
    <div class="main-BG d-flex justify-content-center">
        <div class="container search-BG">
            <AutoComplete 
                v-model="query" :suggestions="filteredJobs" :dropdown="true"
                :placeholder="source['placeholder']" @item-select="selectItem" @complete="search($event)" field="name" class="search-txt" />
            <!-- <div class="w-100"> -->
            <button @click="handleSearch" class="search-BT bold">{{source['button']}}</button>
            <!-- </div> -->

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            query: null,
            filteredJobs: null,
        }
    },
    computed: {
        source() {
            
            return this.$store.getters.getSource['search-bar']
        }
    },
    methods: {
        search(event) {
            this.filteredJobs = this.$store.getters.searchJobs(event)
        },
        selectItem(e) {
            if (this.$route.name === 'JobsDetail' && e.value.id) {
                window.location.href = `/jobs/all/list/${e.value.id}`
                return;
            }
            if (e.value.id) {
                this.$router.push(`/jobs/all/list/${e.value.id}`)
            } else {
                this.$router.push(`/jobs/all/list`)
            }
        },
        handleSearch(e) {
            const query = this.query ? this.query: ''
            this.$router.push({path: '/jobs/all/list', query: {q: query}})
        }
    }
}
</script>

<style scoped lang="scss">

@import "../assets/styles/variables.scss";
.search-BT{
    height: 46px;
    color: #FFF;
    background: $yellow;
    border-radius: 100px;
    padding: 5px 20px;
    margin: 20px 20px;
    border: 0px;
    box-shadow: -1px 1px 5px #5b5b5b70;
    border: 3px solid #56bcb5;
    &:hover{
        background: #f3ce00;
    }
}
.search-BG span.select2-container.select2-container--below{
    min-width: 60vw;
}
.search-txt{
    width: calc(100% - 136px);
}
@media (max-width: 994px) { 
    .search-BG{padding-top: 20px;}
    .search-txt{width: 100%;}
    .search-BT{
        max-height: 46px;
        width: calc( 100% - 6px);
        padding: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }
}
</style>

