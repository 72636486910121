import axios from 'axios'
import content from '../data/content.json'


const apiUrl = process.env.VUE_APP_API_ENDPOINT
const manatalEndpoint = process.env.VUE_APP_MANATAL_ENDPOINT
const googleSheetId = process.env.VUE_APP_NEWS_GOOLE_SHEET_ID || '1_k0mlVRlWc1Jt4BaAINJAWSu1jWBCLQTWOHKg9pu9IQ'

const apiClient = axios.create({
  baseURL: apiUrl
})

export default {
  getNews() {
    // const url = `https://opensheet.elk.sh/${googleSheetId}/1`
    // return axios({
    //   method: 'GET',
    //   url: url,
    // })
    return content;
  },
  getAllJobs() {
    return apiClient.get('/all-jobs')
  },
  getJobs(q, page) {
    const url = `${apiUrl}/jobs?q=${q}&page=${page}`
    return axios({
      method: 'GET',
      url: url
    })
  },
  getSpecialPrograms() {
    const url = `${apiUrl}/sp-jobs`
    return axios({
      method: 'GET',
      url: url
    })
  },
  getJobById(id) {
    return apiClient.get(`/jobs/${id}`)
  },
  createCareer(data, jobId) {
    return axios({
      method: "POST",
      url: `${apiUrl}/career/${jobId}`,
      data: data,
    })
  },
  submitApplicationForm(data, jobId) {
    return axios({
      method: "POST",
      url: `https://api.manatal.com/open/v3/career-page/${manatalEndpoint}/jobs/${jobId}/application-form/`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      }
    })
  },
  getForm(jobId) {
    return apiClient.get(`/career/${jobId}`)
  },

  submitCareer(data, resume, jobId) {
    const formData = new FormData()
    for(const key in data) {
      formData.append(key, data[key])
    }

    if (resume) {
      formData.append('resume', resume)
    }
    return axios({
      method: "POST",
      url: `${apiUrl}/application-form/${jobId}`,
      data: formData,
      headers: {
        Accept: 'application/json',
        "Content-Type": "multipart/form-data",
      }
    })
  },
}