<template src="./news_detail.html"></template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap/dist/js/bootstrap.bundle'


import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import searchBG from '@/components/searchBG.vue'; // @ is an alias to /src
import LocalEventService from '@/services/LocalEventService.js'
import EventService from "@/services/EventService.js"
import Convertor from '@/services/Convertor.js'

// @Component({
//   components: {
//     searchBG,
//   },
// })
export default {
    components: {
        searchBG,
    },
    data() {
        return {
            // mainImg : require(`@/assets/images/icon/WE_blog${this.$route.params.id}.png`),
            news: {},
            related_news: [],
            newsList: [],
            mainImg : require(`@/assets/images/icon/News_d4.png`),
            window: {
                width: 0,
                height: 0
            },
            _isMore: true,
            _allBlog : true,
            url: window.location.href,
        }
    },
    computed: {
      source() {
        console.log(this.$store.getters.getSource['newsDetail']);
        return this.$store.getters.getSource['newsDetail']
      }
    },
    methods: {
        matchHeight () {
        },
        clickMore(){
            console.log("click")
            $('.blogBody').addClass('h-auto')
            $('.more-BG').remove()
        },
        changeNews(id) {
          this.$router.push(`/news/${id}`, () => {
            this.news = this.newsList.find(n => n.id === id)
            const relates = this.news.related_news
            if (relates) {
                const relate_ids = relates.split(',').map(r => r.trim())
                const related_news = this.newsList.filter(n => relate_ids.includes(n.id))
                this.related_news = related_news
            }
          })
        },
        fetchNews() {
              const newsList = EventService.getNews()
              this.newsList = newsList
              const news = newsList.find(n => n.id === this.$route.params.id)
              this.news = news
              const relates = news.related_news
              if (relates) {
                  const relate_ids = relates.split(',').map(r => r.trim())
                  const related_news = newsList.filter(n => relate_ids.includes(n.id))
                  this.related_news = related_news
              }
          // old code
          // EventService.getNews()
          //   .then(response => {
          //     const newsList = response.data
          //     this.newsList = newsList
          //     const news = newsList.find(n => n.id === this.$route.params.id)
          //     this.news = news
          //     const relates = news.related_news
          //     console.log(relates)
          //     if (relates) {
          //         const relate_ids = relates.split(',').map(r => r.trim())
          //         const related_news = newsList.filter(n => relate_ids.includes(n.id))
          //         this.related_news = related_news
          //     }
          // })
          // .catch (error => {
          //   console.log(error)
          // })
        },
    },
    created() {
      this.fetchNews()
      // LocalEventService.getNewsData()
      // .then(response => {
      //   const newsList = response.data
      //   this.newsList = newsList
      //   const news = newsList.find(n => n.id === this.$route.params.id)
      //   this.news = news
      //   const relates = news.related_news
      //   if (relates) {
      //       const relate_ids = relates.split(',').map(r => r.trim())
      //       const related_news = newsList.filter(n => relate_ids.includes(n.id))
      //       this.related_news = related_news
      //   }
      //   this.fetchNews()
      // })
      // .catch(error => {
      //   console.log(error)
      // })
  },
    mounted () { 
        console.log('mounted!')
        this.$nextTick(function () {
        this.matchHeight()
        })
    },
    
}
</script>
<style scope src="./news_detail.scss" scoped lang="scss"></style>
<style>
.kg-image {
  max-width: 100%;
}
</style>
