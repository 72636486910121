<template src="./special_program_list.html"></template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import $ from "jquery";
window.$ = $;
window.jQuery = $;
import "bootstrap/dist/js/bootstrap.bundle";

import VueCarousel from "vue-carousel";
import EventService from "@/services/EventService.js";
Vue.use(VueCarousel);

import searchBG from "@/components/searchBG.vue"; // @ is an alias to /src

const pictures = {
  'SP000000010': "/static/assets/sp/1-min.jpg",
  "SP000000020": "/static/assets/sp/2-min.jpg",
  "SP000000030": "/static/assets/sp/3-min.jpg",
  "SP000000040": "/static/assets/sp/4.jpg",
  "SP000000099": "/static/assets/sp/5_new.jpg"
};

const picturesMB = {
  'SP000000010': "/static/assets/sp/1.jpg",
  "SP000000020": "/static/assets/sp/2.jpg",
  "SP000000030": "/static/assets/sp/3.jpg",
  "SP000000040": "/static/assets/sp/4.jpg",
  "SP000000099": "/static/assets/sp/5-min.jpg"
};

// @Component({
//   components: {
//     searchBG,
//   },
// })
export default {
  components: {
    searchBG
  },
  data() {
    return {
      jobs: this.$store.state.spList
    };
  },
  methods: {
    displayJobName(job) {
      if (this.$store.state.lang === "TH") {
        return job.custom_fields?.positionnameth
          ? job.custom_fields.positionnameth
          : job.position_name;
      }
      return job.position_name;
    },
    displayOverview(job) {
      if (this.$store.state.lang === "EN") {
        return job.custom_fields.joboverview.split("|")[0];
      }
      return job.custom_fields.joboverview.split("|")[1];
    },
    getImage(name) {
      if (pictures[name]) {
        return pictures[name];
      }
      return "/static/assets/sp/default.png";
    },
    getImageMobile(name) {
      if (picturesMB[name]) {
        return picturesMB[name];
      }
      return "/static/assets/sp/default.png";
    }
  },
  computed: {
    source() {
      return this.$store.getters.getSource["sp"];
    }
  },
  created() {
    if (this.jobs?.length === 0) {
      EventService.getSpecialPrograms()
        .then(response => {
          this.jobs = response.data;
          this.$store.commit("setSpList", response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style src="./special_program_list.scss" scoped lang="scss"></style>
