<template src="./job.html"></template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap/dist/js/bootstrap.bundle'


import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import searchBG from '@/components/searchBG.vue'; // @ is an alias to /src

// @Component({
//   components: {
//     searchBG,
//   },
// })
export default {
    components: {
        searchBG,
    },
    data() {
        return {
            banners: {
              'head-office': {"image": "/static/assets/jobs/banner_1.jpg"},
              'store': {"image": "/static/assets/jobs/banner_2.jpg"},
              'dc': {'image': "/static/assets/jobs/banner_3.jpg"}
            },
            window: {
                width: 0,
                height: 0
            },
            _isMore: true,
            _allBlog : true,
        }
    },
    computed: {
        source() {
            return this.$store.getters.getSource['job-detail'][this.$route.params.slug]
        },
        core() {
            return this.$store.getters.getSource['job-detail']['core']
        },
        banner() {
            return this.banners[this.$route.params.slug]
        }
    },
    methods: {
        
    },
    mounted () { 
        this.$nextTick(function () {
        })
    },
    
}
</script>
<style src="./job.scss" scoped lang="scss"></style>
