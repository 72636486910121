<template src="./sp_form_success.html"></template>

<script scoped>
import { Component, Prop, Vue } from "vue-property-decorator";
import $ from "jquery";
window.$ = $;
window.jQuery = $;
import "bootstrap/dist/js/bootstrap.bundle";
// import '@/assets/styles/theme.scss'
import "../../assets/styles/dropdown.scss";

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import searchBG from "@/components/searchBG.vue"; // @ is an alias to /src
import EventService from "@/services/EventService.js";
export default {
  components: {
    searchBG
  },
  data() {
    return {
      mainImg: require(`@/assets/images/icon/Job_banner.png`),
      filter: 0,
      dfilter: 0,
      success: false,
      selected: 1,
      listAction: [
        { name: "Clear All", code: 1 },
        { name: "Apply", code: 2 }
      ],
      isLoading: true,
      job: {}
    };
  },
  methods: {
    displayJobName(job) {
      if (!job || !job.position_name) return;
      if (this.$store.state.lang === "TH") {
        return job.custom_fields?.positionnameth
          ? job.custom_fields.positionnameth
          : job.position_name;
      }
      return job.position_name;
    },
    setDetail(val) {
      if (!val) return (this.dfilter = 0);
      if (window.innerWidth > 994) {
        // setTimeout(() => {
        this.dfilter = this.filter;
        // }, 1000);
      } else {
        this.dfilter = this.filter;
      }
    },
    clickMore() {
      $(".list-BG").addClass("h-auto");
      $(".more-BG").remove();
    },
  },
  created() {
    EventService.getJobById(this.$route.params.id)
      .then(response => {
        console.log(response.data);
        this.job = response.data;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
      });
  },
  computed: {
    source() {
      return this.$store.getters.getSource["job-success"];
    },
    source2() {
      return this.$store.getters.getSource["sp"];
    },
    sourceLink() {
      return this.$store.getters.getSource["link"];
    }
  },
  mounted() {
    // if(window.innerWidth>994){
    setTimeout(() => {
      this.success = true;
    }, 1100);
    // }else{
    //     this.success = true
    // }
    // this.$nextTick(function () {
    // })
  }
};
</script>
<style src="./sp_form_success.scss" scoped lang="scss"></style>
