import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSocialSharing from 'vue-social-sharing'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'


Vue.config.productionTip = false


// import '@/assets/styles/theme.css'
import '@/assets/styles/style.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '../node_modules/primevue/resources/primevue.min.css';
import '../node_modules/primeflex/primeflex.min.css';
// import 'primeflex/primeflex.css';
import '../node_modules/primeicons/primeicons.css';
// import 'prismjs/themes/prism-coy.css';
// import './assets/styles/flags.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(VueSocialSharing)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueLoading)
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
Vue.use(PrimeVue, {
  zIndex: {
      modal: 1100,        //dialog, sidebar
      overlay: 1000,      //dropdown, overlaypanel
      menu: 1000,         //overlay menus
      tooltip: 1100       //tooltip
  }
});

// import VueCarousel from 'vue-carousel';
// Vue.use(VueCarousel);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Dropdown', Dropdown);
Vue.component('Calendar', Calendar);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
