<template src="./home.html"></template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap/dist/js/bootstrap.bundle'


import VueCarousel from 'vue-carousel';
import searchBG from '@/components/searchBG.vue';
import LocalEventService from '@/services/LocalEventService.js'
import EventService from "@/services/EventService.js"
import Convertor from '@/services/Convertor.js'
Vue.use(VueCarousel);
export default {
  components: {
    searchBG,
  },
  data() {
    return {
      newsList: [],
      allJobs: [],
      hId: 0,
      HoverId: 0,
      HoverIn: false,
      isHovering: false,
      newsImg_:require('@/assets/images/icon/news_1.png'),
      newsImg:[require('@/assets/images/icon/news_1.png'),require('@/assets/images/icon/news_2.png'),require('@/assets/images/icon/news_1.png'),require('@/assets/images/icon/news_3.png'),require('@/assets/images/icon/news_4.png')],
    }
  },
  computed: {
    source() {
      return this.$store.getters.getSource['home']
    }
  },
  mounted() {
    this.$nextTick(function () {
      console.log("Home page")
    })
  },
  created() {
    LocalEventService.getNewsData()
      .then(response => {
        const newsList = response.data
        this.newsList = newsList
        this.fetchNews()
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    fetchNews() {
      const newsList = EventService.getNews();
      this.newsList = newsList.sort((a,b) => {
            return b.id - a.id
      })
      // old code
      // EventService.getNews()
      //   .then(response => {
      //     const newsList = response.data
      //     this.newsList = newsList.sort((a,b) => {
      //       return b.id - a.id
      //     })
      //     console.log(this.newsList)
      //   })
      //   .catch (error => {
      //     console.log(error)
      //   })
    },
    onDropdownClick(e) {
      console.log(e)

    },
    getNewsCls(index) {
      switch(index) {
        case 0:
        case 3:
          return 'col-md-8 web'
        default:
          return 'col-md-4 web'
      }
    },
    getNewsImage(news, index) {
      switch(index) {
        case 0:
        case 3:
          return news.thumbnail_lg
        default:
          return news.thumbnail
      }
    },
    goToNews(id) {
      console.log(id)
    },
    goToBanner() {
      window.location.replace('https://www.careers-page.com/tesco-lotus-4/job/3V7R6W')
    },
  }
}

</script>
<style src="./home.scss" scoped lang="scss"></style>