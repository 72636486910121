import Vue from 'vue'
import Vuex from 'vuex'
import en from '@/lang/en.json'
import th from '../lang/th.json'

Vue.use(Vuex)

export interface State {
  jobs: any[],
  joblocates: string[],
  departments: any,
  all_departments: any[],
  query: string,
  lang: any,
  source: any,
  spList: any[],
}

const defaultLang = localStorage.getItem('lang') ? localStorage.getItem('lang'): 'TH'
const source = {
  'TH': th,
  'EN': en,
}

document.title = defaultLang === 'TH' ? 'โลตัส เราใส่ใจคุณ': 'Lotus’s Career';

export default new Vuex.Store<State>({
  state: {
    jobs: [],
    joblocates: [],
    departments: {'Head Office': [], 'DC': [], 'Store': []},
    all_departments: [],
    query: '',
    lang: defaultLang,
    source: source,
    spList: [],
  },
  getters: {
    searchJobs: (state) => (e:any) => {
      const result = state.jobs.filter(j => j.position_name.toLowerCase().includes(e.query.toLowerCase()) ||  (j.joblocate && j.joblocate.toLowerCase().includes(e.query.toLowerCase())))
      const filteredJobs = result.map(r => {
        return {
          ...r,
          name: `${r.position_name}${r.joblocate ? ` (${r.joblocate})`: ''}`,
        }
      })
      filteredJobs.push({name: "View All Jobs", joblocate: '', id: ''})
      return filteredJobs
    },
    filterJobs: (state) => (e:any) => {
      const result = state.jobs.filter(j => j.position_name.toLowerCase().includes(e.query.toLowerCase()) ||  (j.joblocate && j.joblocate.toLowerCase().includes(e.query.toLowerCase())))

      return result
    },
    getSource: (state) => {
      return state.source[state.lang]
    },
  },
  mutations: {
    setInitialState (state, data: State) {
      
      state.jobs = [...data.jobs]
      state.joblocates = [...data.joblocates]
      state.departments = {...data.departments}
      state.all_departments = [...data.all_departments]
    },
    setQuery (state, query: string) {
      state.query = query
    },
    setLang (state, lang: string) {
      state.lang = lang
      localStorage.setItem('lang', lang)
      document.title = lang === 'TH' ? 'โลตัส เราใส่ใจคุณ': 'Lotus’s Career';
    },
    setSpList (state, list: any[]) {
      state.spList = [...list]
    },
  },
  actions: {
  },
  modules: {
  }
})
