<template src="./header.html"></template>

<script>
const RESUME_ID = process.env.VUE_APP_RESUME_ID || 206890
export default {
    data() {
        return {
          lang: this.$store.state.lang,
          resumeId: RESUME_ID,
        }
    },
    computed: {
      source() {
        return this.$store.getters.getSource['top-menu']
      }
    },
    methods: {
      setLang(lang) {
        this.lang = lang
        this.$store.commit('setLang', lang)
      },
      collapse() {
        $('#navbarNav').removeClass('show')
        $('#lang').removeClass('show')
      },
    }
}
</script>

<style src="./header.scss" scoped lang="scss"></style>