<template src="./werlotus_detail.html"></template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap/dist/js/bootstrap.bundle'


import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import searchBG from '@/components/searchBG.vue'; // @ is an alias to /src

// @Component({
//   components: {
//     searchBG,
//   },
// })
export default {
    components: {
        searchBG,
    },
    data() {
        return {
            mainImg : require(`@/assets/images/icon/WE_blog${this.$route.params.id}.png`),
            window: {
                width: 0,
                height: 0
            },
            _isMore: true,
            _allBlog : true,
        }
    },
    methods: {
        matchHeight () {
            console.log($('.blogBody').height(),window.innerHeight)
            // let height = this.$refs.infoBox.clientHeight;
            setTimeout(() => {
                let height = this.$refs.elememt.clientHeight
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
                console.log(this.$refs)
                console.log(this.$refs.elememt)
                if(height > window.innerHeight) this._isMore = true
                console.log(height,height > window.innerHeight,this._isMore)
            }, 500);
        },
        clickMore(){
            $('.blogBody').addClass('h-auto')
            $('.more-BG').remove()
        }
    },
    mounted () { 
        this.$nextTick(function () {
            // this._hiddenBlog = true
            console.log(screen.height)
        // Code that will run only after the
        // entire view has been rendered
        this.matchHeight()
        })
    },
    
}
</script>
<style src="./werlotus_detail.scss" scoped lang="scss"></style>
