import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import Home from '../views/Home.vue'

import Home from '../components/home/Home.vue';
import werlotus from '../components/werlotus/werlotus.vue';
import werlotus_detail from '../components/werlotus_detail/werlotus_detail.vue';
import news from '../components/news/News.vue';
import news_detail from '../components/news_detail/news_detail.vue';
import job from '../components/job/job.vue';
import job_list from '../components/job_list/job_list.vue';
import job_detail from '../components/job_detail/job_detail.vue';
import job_form from '../components/job_form/job_form.vue';
import job_form_success from '../components/job_form_success/job_form_success.vue';
import contactus from '../components/contactus/contactus.vue';
import diversity from '../components/diversity/diversity.vue';
import job_not_found from '../components/job_not_found/job_not_found.vue'
import job_landing from '../components/job_landing/JobLanding.vue'
import sp_list from '../components/special_program_list/special_program_list.vue'
import sp_detail from '../components/sp_detail/sp_detail.vue'
import sp_form from '../components/sp_form/sp_form.vue'
import sp_success from '../components/sp_form_success/sp_form_success.vue'
import resume_form from '../components/resume_form/resume_form.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/WeAreLotus',
    name: 'We are lotus',
    component: werlotus
  },
  {
    path: '/WeAreLotus/:id',
    name: 'We are lotus detail',
    component: werlotus_detail
  },
  {
    path: '/news',
    name: 'News',
    component: news
  },
  {
    path: '/resume',
    name: 'resume',
    component: resume_form
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    component: news_detail
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: job_landing
  },
  {
    path: '/jobs/:slug',
    name: 'JobDetail',
    component: job
  },
  {
    name: 'JobNotFound',
    path: '/jobs-not-found',
    component: job_not_found
  },
  {
    path: '/jobs/:slug/list',
    name: 'JobsFilter',
    component: job_list
  },
  {
    name: 'JobSuccess',
    path: '/jobs/:slug/list/:id/success',
    component: job_form_success
  },
  {
    path: '/jobs/:slug/list/:id/form',
    name: 'JobDetailForm',
    component: job_form
  },
  {
    path: '/jobs/:slug/list/:id',
    name: 'JobsDetail',
    component: job_detail
  },
  {
    path: '/sp/:id/success',
    name: 'spSuccess',
    component: sp_success
  },
  {
    path: '/sp/:id/form',
    name: 'spForm',
    component: sp_form
  },
  {
    path: '/sp/:id',
    name: 'spDetail',
    component: sp_detail
  },
  {
    path: '/sp',
    name: 'spList',
    component: sp_list
  },
  {
    path: '/contactus',
    name: 'Contace us',
    component: contactus
  },
  {
    path: '/diversity',
    name: 'Diversity',
    component: diversity
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
