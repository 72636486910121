
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  // @Prop() private msg!: string;
  // msg!: string
  lang(lang:string){
    console.log("lang : ",lang)
  }
}